import React, { useState } from 'react'
import { AnimatePresence, m, motion } from 'framer-motion'

const qeaVariants = {
    initial : {
        height : 0,
        opacity: 0
    },
    animate : {
        opacity: 1,
        height : 'auto',
    },
    exit : {
        height : 0,
        opacity: 0
    }
}

const QeABook = ({ question, answer, index }) => {
    const [open, setOpen] = useState(false);

    return <div className="rounded bg-[#FFF8D3]">
        <div className="bg-[#F2A22B] 
        rounded 
        p-2 
        text-lg
        hover:bg-[#D2820B]
        cursor-pointer
        flex
        justify-between
        "
            onClick={() => setOpen(!open)}
        >
            <p>
                {question}
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d={!open ? "M19.5 8.25l-7.5 7.5-7.5-7.5" : "M4.5 15.75l7.5-7.5 7.5 7.5"} />
            </svg>


        </div>

        
            <div
                key={open ? "minus" : "plus"}
                variants={qeaVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >{open && <p variants={qeaVariants} key={open ? "minus" : "plus"} className='p-2'>{answer}</p>}</div>
    </div>
}

export default QeABook