
import { QeA } from "../components/QeA"
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { motion } from 'framer-motion';

const titleVariants = {
    hidden: {
        x: '-100vw',
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            delay: 0.8,
            duration: 2,
            stiffness: 40
        }
    }
}

const homeButtonsVariants = {
    initial: {
        opacity: 0,
        x: '-100vw'
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.8,
            duration: 2,
            type: 'spring',
            stiffness: 40
        }
    }
}


function Home() {
    return (
        <main className="bg-beige">
            <div className="flex flex-col">
                <div className="flex flex-col-reverse md:flex-row mb-3 md:mb-0 md:items-center">
                    <div className="text-center h-24 flex flex-col justify-center text-secondary space-y-0.5 md:basis-1/2">
                        <p className="md:text-6xl text-4xl font-title">Larissa Assmann</p>
                        <p className="uppercase text-xs md:text-sm">Consultora de Imagem</p>
                    </div >
                    <hr className="border-secondary/60" />
                    <img className="h-80 mx-auto  md:self-end md:h-96 lg:h-[30rem] md:max-w-[40%] " alt="home" src="newlari.png" />

                </div>
                <hr className="border-secondary/60" />
                <span className="p-8 font-rufina text-brown max-w-xl self-center space-y-2 text-sm md:text-lg">
                    <p className="">
                        Estrategista de imagem, formada em Coloração pessoal, visagismo e Consultoria de Imagem. Atuo capacitando empresárias e futuras líderes a se destacarem profissionalmente através da imagem pessoal.
                    </p>
                    <p className="">
                        Atendi mais de 100 mulheres em diversos estados, sou palestrante e criadora do Método LA, que combina visagismo simulado com estratégias de estilo personalizadas. Ofereço consultorias presenciais e online.
                    </p>
                </span>
                <div className="flex justify-center items-center gap-4 text-yellow-950">
                    {/* TODO: Adicionar links */}
                    <a href="https://www.instagram.com/larissaassmann?igsh=c2JnNTYzb2xtY3R1&utm_source=qr"><FaInstagram className="w-8 h-8" /></a>
                    <a href="mailto:consultorialarissa@outlook.com"><MdMailOutline className="w-8 h-8" /></a>
                    <a href="https://wa.link/m3vf09"><FaWhatsapp className="w-8 h-8" /></a>
                </div>
            </div>
            <hr className="border-secondary/60 my-6" />
            <div className="bg-beige pt-2 px-2 pb-12 space-y-8">
                <h2 className="font-bree text-secondary text-3xl text-center">Serviços Personalizados</h2>
                <div className="flex flex-col justify-center font-rufina space-y-4 md:max-w-xl md:mx-auto">

                    <div className="flex justify-center bg-brown rounded-lg text-beige">
                        <div className="flex basis-1/2">
                            <img className="rounded-l-lg" alt="método LA" src="/metodolaimage.jpg" />
                        </div>
                        <div className="flex flex-col basis-1/2  justify-center py-1 gap-2">
                            <img className="bg-transparent px-4" alt="método LA" src="/metodola.png" />
                            <p className="px-2 text-[9px] md:text-[12px] text-center">Descubra os cortes de cabelo, penteados e colorações que melhor se adequam a você através do método LA. Um serviço personalizado que inclui uma análise detalhada das suas características faciais para garantir resultados que realcem sua beleza.</p>
                            <a className="self-center" href="https://wa.link/y2uaxa"><button className="text-xs text-brown rounded-xl flex md:text-lg py-0.5 px-3 bg-beige">
                                Saber mais
                            </button></a>
                        </div>

                    </div>

                    <div className="flex justify-center bg-brown rounded-lg text-beige">
                        <div className="flex basis-1/2">
                            <img className="rounded-l-lg" alt="Consultoria de imagem" src="/consultoriaimagem.jpg" />
                        </div>
                        <div className="flex flex-col basis-1/2  justify-center gap-2">
                            <h2 className="text-sm md:text-xl px-1 text-center ">Consultoria de Imagem</h2>
                            <p className="px-2 text-[9px] text-center md:text-[12px]">Consultoria exclusiva de refinamento de imagem pessoal. Desenvolva uma aparência autêntica e confiante que reflete sua personalidade, elevando sua presença pessoal e profissional.</p>
                            <a className="self-center" href="https://wa.link/fuhcjz"><button className="text-xs text-brown rounded-xl flex md:text-lg py-0.5 px-3 bg-beige">
                                Saber mais
                            </button></a>
                        </div>

                    </div>

                    <div className="flex justify-center  bg-brown rounded-lg text-beige">
                        <div className="flex basis-1/2">
                            <img className="rounded-l-lg" alt="Coloração pessoal" src="/coloracao.jpg" />
                        </div>
                        <div className="flex flex-col basis-1/2  justify-center py-1 gap-4 ">
                            <h2 className="text-sm px-1 text-center  md:text-xl">Coloração Pessoal</h2>
                            <p className="px-2 text-[9px] text-center md:text-[12px]">Descubra sua paleta de cores personalizada - o primeiro passo para aprimorar sua imagem e destacar sua autenticidade.</p>
                            <a className="self-center" href="https://wa.link/nbsjwy"><button className="text-xs md:text-lg text-brown rounded-xl flex py-0.5 px-3 bg-beige">
                                Saber mais
                            </button></a>
                        </div>
                    </div>
                </div>

            </div>

            <hr className="border-secondary/60" />

            <div className="bg-gradient-to-b from-beige to-white font-rufina flex flex-col justify-center items-center pt-12 px-4 gap-4 text-yellow-950">
                <p className="text-xl">SUPORTE</p>
                <span className="text-center">
                    <p>Ficou com alguma dúvida ou precisa de ajuda para algo? </p>
                    <p>Fale agora mesmo comigo!</p>
                </span>
                <a href="https://wa.link/m3vf09">
                    <button className="bg-brown text-beige rounded-lg py-2 px-4">ENTRAR EM CONTATO</button>
                </a>
            </div>
            {/*
            <div className="text-white bg-gradient-to-tr from-[#956d4f] to-[#B58d6f]  p-2 rounded-b-xl overflow-none">
                <div className="flex flex-col">
                    <div className="flex flex-col p-4">
                        <p className="pt-4 text-2xl font-libre">MUITO PRAZER!</p>
                        <p className="text-2xl font-bold font-title">Sou a Larissa</p>
                        <div className="flex w-32 border-t border-amber-400"></div>
                    </div>
                    <div className="p-4 flex flex-col gap-6">
                        <p className="drop-shadow-lg">Sou Consultora de Imagem e Estilo, apaixonada por transformar a vida de mulheres, ajudando-as a encontrar sua verdadeira essência. Desde sempre acredito que a moda não se trata apenas de roupas, mas sim de contar uma história sobre quem somos. É por isso que minha missão como consultora vai além de simplesmente escolher roupas elegantes; é sobre desbloquear a confiança e a autenticidade que todas nós temos dentro de nós. </p>
                        <p className="drop-shadow-lg">Minha paixão, minha missão e meu compromisso com o empoderamento através da moda me tornam uma Consultora de Imagem e Estilo que deseja fazer a diferença na vida de cada cliente.</p>
                        <p className="drop-shadow-lg">Se você está procurando uma transformação completa, não apenas no seu guarda-roupa, mas na sua confiança e autoexpressão, estou aqui para te guiar nessa emocionante jornada.</p>
                    </div>
                </div>


            </div>
            <div
                className="
                    w-full
                    p-2
                    flex
                    flex-col
                    items-center
                    "
            >
                <p className="text-7xl p-2 font-title text-center ">Meus objetivos</p>
                <hr className="w-full my-2 border border-amber-400 " />
                <div className="container">
                    <div className="flex flex-col text-xl p-8 gap-4">
                        {objetivos.map((o, index) =>
                            <Objetivo key={o} index={index} text={o} />
                        )}
                    </div>
                </div>
            </div>

            <div
                className="
                w-full
                bg-[#956d4f]
                rounded-3xl
                "
            >
                <DuvidasFrequentes />
            </div>
            */}

            <div className="bg-white w-full">
                <img className="bg-white max-h-72 mx-auto" alt="logotipo" src="/logotipo.png" />
            </div>
        </main>

    )
}



const objetivos = [
    "Desenvolver o seu estilo pessoal",
    "Fazer com que você se sinta confiante e segura com sua imagem pessoal",
    "Te tirar da sua zona do conforto, saindo do básico",
    "Te ajudar a se destacar e ocupar seu lugar de sucesso no mundo",
    "Valorizar suas características únicas",
    "Fazer com que você tenha praticidade no seu dia a dia, acabando com a dor de cabeça ao montar um look"
]

const faq = [
    {
        q: 'Os atendimento são somente presenciais?',
        r: 'Não, alguns serviços podem ser realizados tanto no presencial quanto no online. Veja na aba de portfólio as opções.'
    }, {
        q: 'Quais são as formas de pagamento?',
        r: 'Dinheiro, pix, cartão de débito e crédito.'
    }, {
        q: 'Como faço para contratar um serviço?',
        r: 'Clique em solicitar um orçamento.'
    }
]


const Objetivo = ({ text, index }) =>
    <div

        className="flex 
  gap-5 
  rounded 
  text-white
  bg-[#956d4f]
  hover:bg-yellow-900
  cursor-pointer
  px-1
  ">
        <div className="text-7xl p-4 my-auto font-libre">
            {index + 1}
        </div>
        <div className="my-auto">
            {text}
        </div>
    </div>

const DuvidasFrequentes = () => <div className="flex flex-col w-full p-8 text-white">
    <div className="font-title
  text-7xl 
  mx-auto 
  p-6
  border-2
  rounded-br-xl
  rounded-tl-xl
  ">Dúvidas frequentes</div>
    <div className="flex flex-col py-8 md:px-8 gap-4">
        {faq.map(({ q, r }, i) => <QeA key={q} question={q} answer={r} index={i} />)}
    </div>
</div>

export default Home;