import React from 'react'
import BtnGarantirMeuEbook from '../../components/BtnGarantirMeuEbook'

const aprender = [
    {
      a: 'Descobrir quais tonalidades de cores mais te favorecem',
      d: 'M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z'
    },
    {
      a: 'Aprender a montar looks com cor de forma estratégica',
      d: 'M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z'
    },
    {
      a: 'Entender sobre o significado de cada cor e como usar intenção atravéz delas',
      d: 'M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18'
    }
  
  ]

const Intro = () => {
    return (
        <div className='flex flex-col bg-gradient-to-b from-[#F5D9EB]'>
            <div className='text-center mx-auto container p-8 text-md md:text-xl'>
                <p>
                    Um guia prático para orientar mulheres que querem dar <b className='bg-orange-500'>um toque de cor aos seus looks e sair da mesmice do dia a dia.</b> Com ele, você vai conseguir <b className='bg-orange-500'>transformar seu visual</b> e deixá-lo super autêntico.
                </p>
            </div>
            <div className='flex justify-center'>
                <BtnGarantirMeuEbook />
            </div>
            <div className='text-center flex flex-col mx-auto container p-8 text-5xl'>
                <p className='mx-auto font-title'>O que você irá aprender no</p>
                <p className='mx-auto font-title underline'>CÓDIGO SECRETO DAS CORES</p>
            </div>
            <div className='flex flex-col gap-5 p-6 mx-auto text-center'>
                {aprender.map(({ a, d }) => <div key={a} className='flex gap-3 learn'>
                    <div className='border-2 p-2 border-zinc-900 rounded-full my-auto'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d={d} />
                        </svg>
                    </div>
                    <p className='my-auto'>{a}</p>
                </div>)}
            </div>
        </div>
    )
}

export default Intro
