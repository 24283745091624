import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Book from "./pages/Book";

function App() {

  return <Routes>
    <Route path='/' element={<Layout />} >
      <Route index element={<Home />} />
      <Route path="ebook" element={<Book />} />
    </Route>
  </Routes>

}

export default App;