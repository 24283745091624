import React from 'react'

const LeiaDeQualquerLugar = () => {
    return (
        <div className='mt-6 bg-black'>
            <div className='flex flex-col m-4 text-center p-8 text-white '>
                <div>
                    <p className='text-3xl'>LEIA SEU E-BOOK DE</p>
                    <p className='text-4xl text-orange-600'>QUALQUER LUGAR</p>
                </div>
                <div className='flex justify-center'>
                    <img className='max-h-96 ml-14' src='mockups.png' alt="leia_de_qualquer_lugar" />
                </div>
                <div className='p-8'>
                    <p className=''>Prático e rápido para lhe ajudar na montagem dos seus looks a qualquer momento.</p>
                </div>
            </div>
        </div>
    )
}

export default LeiaDeQualquerLugar