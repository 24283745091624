import React, { useEffect, useState } from 'react'
const dia = new Date(2024, 0, 25);

function calculateTimeRemaining() {
    const timeDiff = dia - new Date();
    if (timeDiff <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((timeDiff / 1000) % 60);
    const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    const hours = Math.floor((timeDiff / (1000 * 60 * 60) % 24));
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return { days, hours: formattedHours, minutes: formattedMinutes, seconds: formattedSeconds };
}

const AcabaEm = () => {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {

        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timeRemaining]);

    return (
        <div className='flex justify-center'>
            <div className='flex flex-col bg-red-700 py-2 px-5 rounded-lg'>
                <p className='text-center'>A oferta acaba em:</p>
                <b className='text-center text-xl'>{timeRemaining.days} dias</b>
                <p className='text-center'>{timeRemaining.hours} : {timeRemaining.minutes} : {timeRemaining.seconds}</p>
            </div>
        </div>
    )
}

export default AcabaEm