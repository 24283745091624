import React from 'react'

const vcque = [
    'Não sabe combinar suas peças coloridas',
    'Deseja saber quais cores valorizam mais sua beleza natural',
    'Quer transmitir sua personalidade atravéz do seu look',
    'Está cansada de usar sempre o mesmo mas deseja mudar seu visual, acrescentando cores nos seus looks'
]

const PraVoce = () => {
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col p-7 gap-5 container mx-auto'>
                <p className='text-center mx-auto text-4xl font-title p-4'>Este e-book é para você que:</p>
                <div className='flex flex-col gap-5 mx-auto'>
                    {vcque.map((v, i) => <div key={v} className='flex gap-4'>
                        <p className='font-title text-2xl'>{i + 1}.</p><p className='text-lg'>{v}</p>
                    </div>)}
                </div>
            </div>
            <p className='mx-auto text-lg bg-orange-500 font-bold px-1'>Atenção</p>
            <p className='mx-auto px-10 pb-8 text-center text-xs'>Mesmo que você já tenha feito ou não sua coloração pessoal com uma consultora de Imagem, você pode aderir a este e-book.</p>
        </div>
    )
}

export default PraVoce