import React from 'react'
import AcabaEm from './AcabaEm'
import BtnGarantirMeuEbook from '../../components/BtnGarantirMeuEbook'

const AdquiraAgora = () => {
    return (
        <div className='bg-black rounded-xl flex flex-col text-white p-8 mx-auto flex-shrink gap-5'>
            <p className='font-title text-center'>Compra <span className='text-orange-500'>100% segura</span> e <b>acesso imediato</b> após 
            <br />confirmação do pagamento.</p>
            <p className='text-center pink text-5xl font-title font-bold'>ADQUIRA AGORA!</p>
            <AcabaEm />
            <p className='text-md md:text-xl mx-auto flex gap-2'>DE
                <span className='line-through decoration-4 decoration-red-700 font-bold'>R$87,00</span> POR APENAS</p>
            <p className='text-white mx-auto text-2xl font-bold'>5x de <span className='text-green-600 text-4xl md:text-6xl'>R$8</span>,08</p>
            <p className='font-title pink mx-auto text-2xl font-bold'>OU <b>R$ 37,00</b> À VISTA</p>
            <BtnGarantirMeuEbook />
        </div>
    )
}

export default AdquiraAgora