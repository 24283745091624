import React from 'react'
import Hero from './ebook/Hero'
import Intro from './ebook/Intro'
import PraVoce from './ebook/PraVoce'
import AdquiraAgora from './ebook/AdquiraAgora'
import Footer from './ebook/Footer'
import LeiaDeQualquerLugar from './ebook/LeiaDeQualquerLugar'

const Book = () => {
  return (
    <div className="flex flex-col bg-white">
      <Hero />
      <Intro />
      <hr className='border-black' />
      <PraVoce />
      <AdquiraAgora />
      <LeiaDeQualquerLugar />
      <Footer />
    </div>
  )
}

export default Book