import React from 'react'
import { motion } from 'framer-motion'

const garantirEbookButtonVariantions = {
    initial: {

    },
    animate: {
        scale: [1, 1.05, 1],
        boxShadow: [
            "0px 0px 0px rgb(0, 0, 0)",
            "0px 0px 8px rgb(0, 0, 0)",
            "0px 0px 0px rgb(0, 0, 0)",
        ],
        transition: {
            duration: 1.6,
            repeat: Infinity
        }
    },
    hover: {
        scale: 1.2
    },
    tap: { scale: 0.8 }
}

const BtnGarantirMeuEbook = () => {
    return (
        <motion.a 
            href='https://pay.kiwify.com.br/TA9u1b9'
            variants={garantirEbookButtonVariantions}
            className='pink-btn mx-auto'
            initial="initial"
            animate="animate"
            whileHover="hover"
            whileTap="tap"
        >
            GARANTIR MEU E-BOOK!
        </motion.a>
    )
}

export default BtnGarantirMeuEbook