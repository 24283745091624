import React from 'react'
import QeABook from '../../components/QeABook'

const faq = [
    {
        q: 'Quando recebo meu e-book?',
        r: 'Assim que finalizado a compra, você recebe seu e-book de imediato por e-mail.'
    }, {
        q: 'Posso pedir reembolso?',
        r: 'Sim, você tem 7 dias de garantia.'
    }, {
        q: 'Posso parcelar?',
        r: 'Sim, pode parcelar até 5x.'
    }
]

const FaQ = () => {
    return (
        <div className="flex flex-col w-full p-8 text-black">
            <div className="font-title
              text-7xl 
              mx-auto 
              p-6
              border-2
              rounded-br-xl
              rounded-tl-xl
              border-black
              ">Dúvidas frequentes</div>
            <div className="flex flex-col py-8 md:px-8 gap-4">
                {faq.map(({ q, r }, i) => <QeABook key={q} question={q} answer={r} index={i} />)}
            </div>
        </div>
    )
}

export default FaQ